<template lang="html">
  <section class="banner-valores">
    <span v-for="(circle, key) in $t(`${view}.circles_banner.circles`)" :key="key"
      :show="selected == circle.id" class="background"
      :style="`background:url(/img/valor-${circle.id}.jpg) center top/cover no-repeat`">
    </span>

    <div class="container">
      <h2 class="main-title">{{ $t('purpose_vision_values.circles_banner.title') }}</h2>
      <div v-html="$t('purpose_vision_values.circles_banner.text')"></div>

      <div class="circles row-center">
        <div v-for="(circle, key) in $t(`${view}.circles_banner.circles`)" :key="key"
          @mouseover="set(circle.id)">
          <h2>{{ circle.title }}</h2>
          <p v-html="circle.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">

export default {
  name: 'banner-valores',
  props: ['view'],
  data: () => ({
    selected: 'sostenibilidad'
  }),
  methods: {
    set(value) {
      this.selected = value;
    }
  }
}
</script>

<style scoped lang="scss">
.banner-valores {}
</style>
<template lang="html">
  <section class="padding-menu exportacion -fondo-campo wrapper">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link :to="$route.path">{{
          $t("navbar_menu.export.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page nuestras-marcas">
      <div class="container">
        <h1>{{ $t("export.title") }}</h1>
      </div>
    </section>

    <section class="slider-intro -exportacion">
      <slick ref="slick" :options="slickOptions">
        <div>
          <article class="banner-grupo-ian -color row-center">
            <router-link class="enlace-cover" :to="$t('paths.quienes-somos')">
            </router-link>
            <h1>{{ $t("home.intro.juntos") }}</h1>
            <!--             <nav class="marcas row-center">
              <router-link :to="$t('paths.empresas-grupo')"><img src="/img/logo-carretilla.png"></router-link>
              <router-link :to="$t('paths.empresas-grupo')"><img src="/img/logo-comar.png" alt=""></router-link>
              <router-link :to="$t('paths.empresas-grupo')"><img src="/img/logo-taboada.png" alt=""></router-link>
              <router-link :to="$t('paths.empresas-grupo')"><img src="/img/logo-norenense.png" alt=""></router-link>
            </nav> -->
            <video
              autoplay="true"
              loop="true"
              playsinline="true"
              muted
              poster="/img/poster.jpg"
            >
              <source src="/img/video-home.mp4" type="video/mp4" />
              <source src="/img/video-home.mov" type="video/mov" />
            </video>
          </article>
        </div>
        <div>
          <article class="exportamos row-center">
            <router-link class="enlace-cover" :to="$t('paths.nuestras-marcas')">
            </router-link>
            <h1>{{ $t("home.intro.exportamos") }}</h1>
          </article>
        </div>
      </slick>
    </section>

    <section class="banner-sobre-nosotros exportacion">
      <div class="container row-end">
        <img :src="$t('export.groupIAN_banner.img')" alt="Sobre nosotros" />
        <div class="texto">
          <h2>{{ $t("export.groupIAN_banner.title") }}</h2>
          <div
            v-html="$t('export.groupIAN_banner.text')"
            style="margin-bottom: 20px;"
          ></div>
          <router-link
            :to="$t('paths.quienes-somos')"
            target="_blank"
            class="arrow-link -white"
          >
            {{ $t("export.groupIAN_banner.btn_text") }}
          </router-link>
        </div>
      </div>
    </section>

    <section class="banner-proposito grupo-ian">
      <div class="container row-start">
        <div class="texto">
          <h2>{{ $t("export.in_the_world_banner.title") }}</h2>
          <div v-html="$t('export.in_the_world_banner.text')"></div>
        </div>
        <img
          :src="$t('export.in_the_world_banner.img1')"
          alt="Verduras"
          class="proposito-1"
        />
        <img
          :src="$t('export.in_the_world_banner.img2')"
          alt="Bol aceitunas"
          class="proposito-2"
        />
        <img
          :src="$t('export.in_the_world_banner.img3')"
          alt="Bodegón frasco esparragos"
          class="proposito-3"
        />
        <img
          :src="$t('export.in_the_world_banner.img4')"
          alt="Verduras"
          class="proposito-4"
        />
        <img
            :src="$t('export.in_the_world_banner.img5')"
            alt="Verduras"
            class="proposito-5"
          />
      </div>
    </section>

    <section class="banner-sobre-nosotros -creamos-tu-marca">
      <div class="container row-end">
        <img :src="$t('export.branding_banner.img')" alt="Creamos tu marca" />
        <div class="texto">
          <h2>{{ $t("export.branding_banner.title") }}</h2>
          <div v-html="$t('export.branding_banner.text')"></div>
        </div>
      </div>
    </section>

    <acceso-productos></acceso-productos>
    <proyectos-home></proyectos-home>
    <banner-valores :view="'export'"></banner-valores>

    <section class="formulario-container">
      <div class="container row-center">
        <h2>{{ $t("export.form.title") }}</h2>
        <div v-html="$t('export.form.text')"></div>
        <formulario></formulario>
      </div>
    </section>
  </section>
</template>

<script lang="js">
import accesoProductos from '@/components/acceso-productos'
import bannerValores from '@/components/banner-valores'
import formulario from '@/components/formulario-contacto';
import proyectoshome from '@/components/proyectos-home'
import Slick from 'vue-slick';

export default {
  name: 'exportacion',
  components: {
    Slick,
    'acceso-productos': accesoProductos,
    'banner-valores': bannerValores,
    'formulario': formulario,
    'proyectos-home': proyectoshome,
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
      },
    }
  }
}
</script>

<style scoped lang="scss">

@media screen and (max-width: 700px) {
  .slider-intro {
    margin-bottom: 0;
    display: inherit;
  }
  .banner-sobre-nosotros,
  .banner-sobre-nosotros.exportacion {
    margin-top: 0;
  }
}
</style>
